ion-toolbar {
    --background: transparent;
    margin-top: env(safe-area-inset-top, 0px);
}

ion-back-button {
  --background: transparent;
  color: black;
    margin-top: env(safe-area-inset-top, 0px);
}

.modal-create-new-profile {
  // color: #3880ff;
  // background: antiquewhite;
  // --background-color: rgb(237, 181, 108);

  // justify-content: center;
  // display: flex;
  // top: 40%;
  // right: 70%;
  --height: 250px;
  --width: 500px;
  --border-radius: 15px;
}

.back {
  position: absolute;
  width: 100%;
  height: 100vh;
  background: #a8fa71;
  // background-image: url('/assets/images/background2.jpg') !important;
  opacity: 1;
  background-size: cover;
  z-index: -50;
}

.colibri-logo {
    width: 80px;
    height: 25px;
    display: table-caption;
}

.colibri-title {
    width: 100px;
    height: 20px;
}

.colibri-logo-wrapper {
    display: flex;

}

.colibri-logo-wrapper:hover {
  cursor: pointer;
}

.tutorial-btn {
text-transform: none;
color: white;
align-self: center;
--background: #8359E3;
justify-content: center;
display: flex;
margin: auto;
margin-top: 5vh;
box-shadow: inset;

}

.ion-text{
  align-items: center;
  float: right;
  margin: 100px;
  margin: 2em;
}

.version {
color: #abccff;
font-size: small;
padding: 10px;
border-right-width: 10px;
text-align: right;
cursor: pointer;
position: absolute;
bottom: 0;
right: 0;
}

.title-configs {
text-align: center;
font-weight: bold;
}

.app-tab-bar .tab-selected {
color: rgba(52, 52, 52, 0.823);
--color-selected: white;
border-width: 0px 0 5px 0px;
border-style: solid;
}

ion-loading.custom-loading {
  --background:white;
  --spinner-color:   #3880ff;

  color:--ion-color-primary;
}

ion-alert.custom-alert {
  --background:white;
}

ion-alert.custom-alert {
  --backdrop-opacity: 0.7;
}

// Resposavel pelo alert da tela de save (teste)

.custom-alert .alert-button-group {
  padding: 8px;
}

button.alert-button.alert-button-confirm {
  background-color: #3880FF;
  color: var(--ion-color-success-contrast);
}

.md button.alert-button.alert-button-confirm {
  border-radius: 4px;
}

.ios .custom-alert button.alert-button {
  border: 0.55px solid rgba(var(--ion-text-color-rgb, 0, 0, 0), 0.2)
}

.ios button.alert-button.alert-button-cancel {
  border-right: 0;
  border-bottom-left-radius: 13px;
  border-top-left-radius: 13px;
}

.ios button.alert-button.alert-button-confirm {
  border-bottom-right-radius: 13px;
  border-top-right-radius: 13px;
}

// fim desse bloco

@media only screen and (max-width: 450px) {
  .back {
    background: #a8fa71;
    // background-image: url('/assets/images/background-mobile3.jpg') !important;
  }
}

.bg-transparent {
background: transparent;
--background: transparent;
}

#circulo {
width: 20px;
height: 20px;
background-color: #8359E3;
border-radius: 50%;
vertical-align: middle;
justify-content: center;
float:left ;
margin: 4px;
text-align: center;
color: white;
font-size: 12px;
display: flex;
place-items: center;
}

img.nome-classe { vertical-align: middle; }

.modal-inclination-mode{
  height: auto!important;
}


// CORES DISPONÍVEIS DO COLIBRI
.blue-color{
  background: var(--color-element-blue);
  // border: 1.5px solid var(--color-element-blue); Nesse modelo não consegue fzer leitura do var
  border-width: 1.5px;
  border-style: solid;
  border-color: var(--color-element-blue);
}

.pink-color{
  background: var(--color-element-pink);
  border-width: 1.5px;
  border-style: solid;
  border-color: var(--color-element-pink);
}

.gray-color{
  background: var(--color-element-gray);
  border-width: 1.5px;
  border-style: solid;
  border-color: var(--color-element-gray);
}

.purple-color{
  background: var(  --color-element-purple);
  border-width: 1.5px;
  border-style: solid;
  border-color: var(  --color-element-purple);

}

.green-color{
  background: var(--color-element-green);
  border-width: 1.5px;
  border-style: solid;
  border-color: var(--color-element-green);
}

.red-color{
  background: var( --color-element-red);
  border-width: 1.5px;
  border-style: solid;
  border-color: var(--color-element-red);
}

.orange-color{
  background: var(  --color-element-orange);
  border-width: 1.5px;
  border-style: solid;
  border-color: var(--color-element-orange);
}

.black-color{
  background: var(  --color-element-black);
  border-width: 1.5px;
  border-style: solid;
  border-color: var(--color-element-black);
} 